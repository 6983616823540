
import { defineComponent, onMounted } from "vue";
import SurveysTable from "@/components/widgets/tables/SurveysTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "surveys",
  components: {
    SurveysTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Surveys");
    });
  },
});
