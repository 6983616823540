
import { defineComponent, inject, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import GlobalTable from "@/components/widgets/tables/GlobalTable.vue";
import { useRouter } from "vue-router";
import DeleteSurvey from "@/components/swal/DeleteSurvey.vue";
import DropdownExport from "@/components/dropdown/DropdownExport.vue";
import moment from "moment";
interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "surveys-table",
  components: { DeleteSurvey, GlobalTable, DropdownExport },
  setup() {
    const router = useRouter();
    let item = ref();
    let change = ref<boolean>(false);
    let updaterID = ref(null);
    let deleterData = ref({});
    let searchDate = ref([]);
    let countryData = ref(0);
    let checkDeleter = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const currentSort = ref<string>("");
    let getDataExist = ref<boolean>(true);
    let search = ref<string | null>(null);
    const allRegions = ref([]);
    let pagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 1,
    });

    let page = ref<number>(1);
    let perPage = ref<number>(8);
    let data = ref([]);
    const regions = ref([]);

    const tableHeader = [
      {
        name: "ID",
        key: "id",
        sortable: false,
      },
      {
        name: "Date",
        key: "date",
        sortable: false,
      },
      {
        name: "Last Update",
        key: "date_updated",
        sortable: false,
      },
      {
        name: "Name",
        key: "fullname",
        sortable: false,
      },
      {
        name: "Company",
        key: "company",
        sortable: false,
      },
      {
        name: "Email",
        key: "email",
        sortable: false,
      },
      {
        name: "Region",
        key: "region",
        sortable: false,
      },
      {
        name: "Completed Sections",
        key: "sections",
        sortable: false,
      },
      {
        name: "Score",
        key: "value",
        sortable: false,
      },
      {
        name: "Survey",
        key: "test",
        sortable: false,
      },
      {
        name: "Result",
        key: "result",
        sortable: false,
      },

      {
        name: "Actions",
        key: "actions",
      },
    ];
    onMounted(() => {
      getDetails(perPage.value, page.value);
      getAllRegions();
    });

    function getDetails(perPage, page) {
      search.value = null;
      loading.value = true;
      let query = `?perPage=${perPage}&page=${page}&country=${countryData.value}`;
      if (searchDate.value && searchDate.value.length > 0) {
        query += `&startDate=${moment(searchDate.value[0]).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(searchDate.value[1]).format("YYYY-MM-DD")}`;
      }
      apiService
        .get(`/users/surveys`, query)
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data?.data;
            //  pagination.value.page = resp.data?.meta.current_page;
            //  pagination.value.total = resp.data?.meta.total;
            //  pagination.value.rowsPerPage = resp.data?.meta.per_page;
            loading.value = false;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }
    const getRegion = (resp) => {
      regions.value = resp;
      console.log(regions.value)
    };
    function searchDetails(perPage, page, query) {
      loading.value = true;
      let uri = `?query=${query}&perPage=${perPage}&page=${page}&country=${countryData.value}`;
      if (searchDate.value && searchDate.value.length > 0) {
        uri += `&startDate=${moment(searchDate.value[0]).format(
          "YYYY-MM-DD"
        )}&endDate=${moment(searchDate.value[1]).format("YYYY-MM-DD")}`;
      }
      apiService
        .get(`/users/surveys/search`, uri)
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data.data;
            //  pagination.value.page = resp.data.meta.current_page;
            //  pagination.value.total = resp.data.meta.total;
            //  pagination.value.rowsPerPage = resp.data.meta.per_page;
            loading.value = false;
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }
    function getAllRegions() {
      apiService
          .get(`/region`, `?column=name&orderType=asc`)
          .then(function (resp) {
            if (resp.data.data) {
              allRegions.value = resp.data?.data;
            }
          });
    }
    const currentPageChange = (val) => {
      page.value = val;
      //  pagination.value.page = val;
      if (search.value != null && search.value != "") {
        searchDetails(perPage.value, val, search.value);
      } else {
        getDetails(perPage.value, val);
      }
    };
    const existData = async (resp) => {
      getDataExist.value = false;
      if (resp) {
        getDataExist.value = await resp;
      }
    };
    const setSearchValue = (event) => {
      search.value = event.target.value.trim();
      if (!search.value?.trim()) {
        getDetails(perPage.value, page.value);
        return;
      }
      searchDetails(perPage.value, page.value, search.value);
    };

    const checkModal = (resp) => {
      if (resp.status == "updated") {
        let array = JSON.parse(JSON.stringify(data.value));
        let foundIndex = array.findIndex((x) => x.id == resp.data.id);
        array[foundIndex] = resp.data;
        data.value = new Proxy(array, {});
      } else if (resp.status == "created") {
        search.value = null;
        searchDate.value = [];
        //  pagination.value.page = resp.data?.meta.current_page;
        //  pagination.value.total = resp.data?.meta.total;
        //  pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      } else if (resp.status == "deleted") {
        search.value = null;
        searchDate.value = [];
        //  pagination.value.page = resp.data?.meta.current_page;
        //  pagination.value.total = resp.data?.meta.total;
        //  pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      }
    };
    const changeCountry = () => {
      if (search.value != null && search.value != "") {
        searchDetails(perPage.value, page.value, search.value);
      } else {
        getDetails(perPage.value, page.value);
      }
    };
    const setSearchDate = () => {
      if (search.value != null && search.value != "") {
        searchDetails(perPage.value, page.value, search.value);
      } else {
        getDetails(perPage.value, page.value);
      }
    };
    const DeleteList = (id, code) => {
      checkDeleter.value = !checkDeleter.value;
      deleterData.value = {
        id: id,
        code: code,
      };
    };

    return {
      loading,
      tableHeader,
      data,
      currentPageChange,
      pagination,
      perPage,
      page,
      search,
      setSearchValue,
      currentSort,
      checkModal,
      item,
      allRegions,
      getDataExist,
      existData,
      updaterID,
      checkDeleter,
      deleterData,
      change,
      DeleteList,
      changeCountry,
      searchDate,
      setSearchDate,
      countryData,
      getRegion,
      regions,
    };
  },
});
